import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const bannerCva = cva(['w-full'], {
  variants: {
    color: {
      primary: ['text-primary'],
      secondary: ['text-secondary'],
      accent1: ['text-accent1'],
      accent2: ['text-accent2'],
      accent3: ['text-accent3'],
      success: ['text-success'],
      white: ['text-white'],
      error: ['text-red']
    },
    type: {
      fullwidth: [''],
      boxed: ['container mx-auto']
    }
  },
  defaultVariants: {
    type: 'fullwidth',
    color: 'primary'
  }
})

export type BannerProps = VariantProps<typeof bannerCva>
