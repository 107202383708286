<template>
  <div class="full-width-component">
    <div
      class="section-banner relative z-[1] -mt-[1px] w-full overflow-hidden"
      :class="[
        bannerCva({ type: data.type }),
        {
          'mb-5': !props.data.marginBottomDisabled
        }
      ]"
      data-testid="banner"
      :style="{
        '--min-height': `${theLowestImageHeight}px`
      }"
    >
      <div class="item min-height relative isolate z-[999] w-full xl:px-0">
        <template v-for="image in images">
          <StrapiImage
            v-if="image.image?.data?.attributes?.url"
            :key="image.image.data.attributes.url"
            :data="image.image.data.attributes"
            :class="{
              'relative z-10': data.description,
              'md:hidden': image.isMobile,
              'hidden md:block': !image.isMobile && images.length > 1
            }"
            class="min-height !absolute inset-0 h-full w-full object-cover object-center"
            data-testid="banner-img"
          />
        </template>

        <div
          v-if="data.description"
          class="container mx-auto h-full !px-0 !py-6"
        >
          <SectionsRichText
            class="relative z-[999]"
            :class="[bannerCva({ color: data.descriptionColor?.name })]"
            :data="{
              content: data.textBackgroundOpacity
                ? addOpacityToStrapiText(
                    data.description,
                    data.textBackgroundOpacity
                  )
                : data.description
            }"
            data-testid="banner-desc"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiSectionsBanner } from '@autobid/strapi-integration/typescript/strapi/sections/SectionsBanner'

import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { bannerCva } from '@autobid/ui/utils/cva/bannerCva'
import StrapiImage from '@autobid/ui/components/common/strapi/Image.vue'
import { addOpacityToStrapiText } from '@autobid/ui/utils/addOpacityToStrapiText'

interface Props {
  data: StrapiComponentProps<StrapiSectionsBanner>
}

const props = defineProps<Props>()

const images = computed(() => {
  const result = [{ isMobile: false, image: props.data.image }]

  if (props.data.imageMobile?.data) {
    result.push({ isMobile: true, image: props.data.imageMobile })
  }

  return result
})

const theLowestImageHeight = computed(() => {
  const lowestImage = images.value.reduce(
    (highest, image) =>
      image.image.data?.attributes.url &&
      image.image.data.attributes.height <
        (highest.image.data.attributes.height ?? 0)
        ? image
        : highest,
    { isMobile: false, image: props.data.image }
  )
  return lowestImage?.image?.data?.attributes?.height
})
</script>

<style lang="scss">
.has-transparent-nav {
  .item {
    --item-pad: 24px;

    padding-top: calc(var(--nav-height) + var(--item-pad));

    @media (min-width: 1024px) {
      padding-top: calc(
        var(--nav-height) + var(--desktop-nav-height) + var(--item-pad)
      );
    }
  }
}

@media (min-width: 768px) {
  .min-height {
    min-height: var(--min-height);
  }
}
.section-banner {
  span[style*='background-color'] {
    padding: 0.2rem 0.7rem;
    display: inline-block;
  }
}
</style>
